import { FC } from 'react';
import Image from 'next/image';
import { Button } from '@/components/buttons/Button';
import { Text } from '@/components/Text';
import { cn } from '@/lib/utils';
import crystalImage from '@/assets/images/crystal-image.png';
import { AnimatedCounter } from '@/components/AnimatedCounter';

type Props = {
  className?: string;
  amount: number;
  onClick: () => void;
};

export const GemButton: FC<Props> = ({ className, amount, onClick }) => {
  return (
    <Button
      onClick={onClick}
      className={cn('w-auto border-black', className)}
      wrapperClass={'bg-pink-gr group-active/button:bg-white-gr justify-start px-[32px] py-3'}
    >
      <div className="w-[24px] h-[24px] flex items-center justify-center w-10 h-10 bg-black-100 rounded-full mr-[6px] relative">
        <div className="w-[24px] h-[24px] z-10 flex items-center justify-center w-10 h-10 bg-black-100 rounded-full">
          <Image className="w-[15px] h-[15px]" src={crystalImage} alt="crystal" />
        </div>
        <div className="z-0 w-[24px] h-[24px] flex items-center justify-center w-10 h-10 bg-pink rounded-full absolute left-[1px] top-[1px]"></div>
      </div>
      <Text className="transition-all duration-150 text-white group-active/button:text-black" variant="btn-1">
        <AnimatedCounter amount={amount} />
      </Text>
    </Button>
  );
};
