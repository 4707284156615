export interface Relationship {
    id: string;
    name: string;
    icon: string;
  }
  
export const relationships: Relationship[] = [
{ id: 'stranger', name: 'Stranger', icon: '🕵️‍♀️' },
{ id: 'classmate', name: 'Classmate', icon: '📚' },
{ id: 'coworker', name: 'Co-worker', icon: '💼' },
{ id: 'teacher', name: 'Teacher', icon: '👩‍🏫' },
{ id: 'girlfriend', name: 'Girlfriend', icon: '🩷' },
{ id: 'fwb', name: 'Friends with Benefits', icon: '🔥' },
{ id: 'wife', name: 'Wife', icon: '💍' },
{ id: 'mistress', name: 'Mistress', icon: '🌙' },
{ id: 'acquaintance', name: 'Acquaintance', icon: '🤝' },
{ id: 'bestfriend', name: 'Best Friend', icon: '🤞' },
{ id: 'stepsister', name: 'Step Sister', icon: '👯‍♀️' },
{ id: 'stepmom', name: 'Step Mom', icon: '👩‍👦' },
];