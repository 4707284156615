import { FC, PropsWithChildren } from 'react';
import { formatPriceInCents } from '@/utils/price';
import Image from 'next/image';
import { Button } from '@/components/buttons/Button';
import { Text } from '@/components/Text';
import { cn } from '@/lib/utils';
import crystalImage from '@/assets/images/crystal-image.png';

type Props = {
  className?: string;
  wrapperClassName?: string;
  currency?: "dollar" | "gem" | null;
  amount: number;
  title: string;
  onClick: () => void;
};

export const UnlockButton: FC<PropsWithChildren<Props>> = ({
  className,
  amount,
  onClick,
  title,
  wrapperClassName,
  children,
  currency = "gem",
}) => {
  return (
    <Button
      onClick={onClick}
      className={cn('w-auto border-black', className)}
      wrapperClass={cn('bg-pink group-active/button:bg-black justify-start px-6', wrapperClassName)}
    >
      {children || (
        <div className="w-full flex items-center justify-center">
          <Text textColor={'white'} variant="btn-1">
            {title}
          </Text>
          {currency === "gem" && (
            <Image className="w-[18px] h-[18px] mx-1" src={crystalImage} alt="crystal" />
          )}
          <Text className="ml-1" textColor={'white'} variant="btn-1">
            {currency === "dollar"? formatPriceInCents(amount): amount}
          </Text>
        </div>
      )}
    </Button>
  );
};
