import React, { FC } from 'react';
import { Modals } from '@/types/modals';
import { useIsAuthenticated } from '@/hooks/useIsAuthenticated';
import { GemButton } from '@/components/buttons/GemButton';
import { useState, useEffect } from 'react';
import { useUserStore } from '@/stores/user';
import { useAppStore } from '@/stores/app';
import { MenuButton } from '@/components/buttons/MenuButton';
import { ChatWelcomeMessage } from '@/components/page-components/chat/ChatWelcomeMessage';
import { Button } from './buttons/Button';
import { Text } from './Text';
import { Promotion } from '@/types/products';
import { openModal } from './BaseModal';
import { useRouter } from 'next/router';
import { useCharactersStore } from '@/stores/characters';
import { useProductsStore } from '@/stores/products';
import { useCountdownTimer } from '@/hooks/useCountdownTimer';
import { cn } from '@/lib/utils';
import posthog from 'posthog-js';

import Image from 'next/image';
import SmileyImage from '@/assets/images/128px-718smiley.png';

export const Header: FC = () => {
  const isLoggedIn = useIsAuthenticated();
  const { characters, loading } = useCharactersStore(s => ({ characters: s.characters, loading: s.loading }));
  const { userStats } = useUserStore();
  const { isSidebarOpen, toggleSidebar, setSignInUiType, setIsSignInModalVisible } = useAppStore(s => s);
  const { getNextPromotion, promotions, hasFreeGems, products } = useProductsStore();
  const [ nextPromotion, setNextPromotion] = useState<Promotion | null>(getNextPromotion());
  const [ freeGems, setFreeGems] = useState<boolean>(hasFreeGems());

  const { timeLeft, timeStr, resetTimer } = useCountdownTimer(nextPromotion?.remaining_time ?? 0, () => {
    setNextPromotion(getNextPromotion);
  });


  const router = useRouter();
  const activeChatId = router.asPath.startsWith('/chat/') ? Number(router.query.id) : null;

  const character = activeChatId && characters.find(ch => ch.id === activeChatId);

  useEffect(() => {
    setNextPromotion(getNextPromotion);
  }, [promotions]);

  useEffect(() => {
    setFreeGems(hasFreeGems());
  }, [products]);

  useEffect(() => {
    resetTimer(nextPromotion?.remaining_time ?? 0);
  }, [nextPromotion]);

  const onClickSignIn = () => {
    setSignInUiType('signinUI');
    setIsSignInModalVisible(true);
    openModal(Modals.SignIn);
    window.gtag && window.gtag('event', 'signin_attempt', {'location': 'header'});
    posthog.capture('signin_attempt', {'location': 'header'});
  }

  const scrollToTop = () => {
    const chatElement = document.getElementById('chat-history-container'); // Ensure you have an element with this ID in your chat component
    if (chatElement) {
      chatElement.scrollTo({
        top: -chatElement.scrollHeight + chatElement.clientHeight,
        behavior: 'smooth',
      });
    }
  };

  const handleMenuButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    toggleSidebar();
  };

  const handleViewStore = () => {
    openModal(Modals.Storage);
    window.gtag && window.gtag('event', 'view_store', {'value': userStats.gem_balance});
    posthog.capture('view_store', {'value': userStats.gem_balance});
  }

  const renderPromotion = () => {
    if (!nextPromotion && !freeGems) return null;
    return (
      <div className="h-11 px-3 py-2 bg-black-100 rounded-3xl justify-center items-center gap-1.5 inline-flex mr-2 border-2 border-pink hover:cursor-pointer relative overflow-hidden group"
        onClick={handleViewStore}
        title={nextPromotion?.name}
    >
      <div className="absolute inset-0 w-full h-full bg-gradient-to-r from-transparent via-white/20 to-transparent -translate-x-full group-hover:translate-x-full transition-transform duration-1000 ease-in-out" />
      <Text className="text-btn-2 md:text-btn-1 relative z-10" textColor="white" variant="btn-1">
        {
          freeGems ? 'Free Gems 👉' : timeStr
        }
      </Text>
    </div>
    );
    
  }
  
  return (
    <header
      onClick={scrollToTop}
      className={cn(
        'bg-black-200 text-white flex justify-end md:justify-between items-center pr-5 md:pr-8 pt-2.5 pb-[15px] transition-all z-[15]',
        isSidebarOpen ? 'pl-3 md:pl-8' : 'pl-[70px] md:pl-[100px]',
      )}
    >
      <div className="flex items-center absolute left-0 ml-5 md:ml-8" onClick={(event) => event.stopPropagation()}>
        <MenuButton className={isSidebarOpen ? 'hidden' : ''} onClick={toggleSidebar} />
      </div>
      {character ? (
        <ChatWelcomeMessage character={character} loading={loading} className="hidden md:flex mb-0" />
      ) : (
        <div className="flex-grow flex items-center overflow-hidden pl-1 md:pl-1"> 
          <div className="flex items-center whitespace-nowrap scrolling-text">
            <Image src={SmileyImage} alt="Smiley" width={25} height={25} className="hidden sm:flex flex-shrink-0 relative top-[1px] mr-1 md:mr-2 md:w-[30px] md:h-[30px]" />
            <Text className="hidden sm:flex text-white text-h6 md:text-h6 font-normal leading-none md:font-normal" variant="h6">
              <span className="hidden md:inline">uncensored AI girlfriends. NSFW chat and images!</span>
              <span className="hidden sm:inline md:hidden">uncensored AI girlfriends. NSFW chat and images!</span>
            </Text>
          </div>
        </div>
      )}
      <div className="flex items-center" onClick={(event) => event.stopPropagation()}>
        {!isLoggedIn ? (
          <Button
            onClick={onClickSignIn}
            className="w-auto"
            wrapperClass="py-[12px] bg-blue-gr group-active/button:bg-white-gr"
          >
            <Text
              className="px-[27px] transition-all duration-150 text-white group-active/button:text-black"
              textColor={'white'}
              variant={'btn-1'}
            >
              Sign in
            </Text>
          </Button>
        ) : (
          <>
            {renderPromotion()}
            <GemButton 
              onClick={handleViewStore}
              amount={userStats.gem_balance} 
            />
          </>
        )}
      </div>
    </header>
  );
};
