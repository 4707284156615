import { Modals } from '@/types/modals';
import { IChatQuotaConfig } from '@/types/chat';
import { useCharactersStore } from '@/stores/characters';
import { showToast } from '@/stores/app';
import { ToastType } from '@/types/toast';
import { UnlockMessagesModal } from './UnlockMessages';

type Props = {
  onComplete: () => void;
  config?: IChatQuotaConfig;
  characterId: number;
};

// This modal is pretty much same as UnlockMessageModal, 
// but it's used for reset, which can be accessible from sidebar.
export const UnlockMessagesResetModal: React.FC<Props> = ({ config, onComplete, characterId }) => {
  const { getById, resetChat } = useCharactersStore(s => ({
    getById: s.getById,
    resetChat: s.resetChat,
    resetingChat: s.resetingChat,
  }));
  const character = getById(characterId);

  const onQuotaHit = () => {
    // This shouldn't happen, 
    // By this time, unlock_message should have been called successfully.
    showToast('Don\'t have enough messages item to reset', ToastType.ERROR);
  }

  const onUnlockMessageComplete = async () => {
    if (characterId) {
      await resetChat(characterId, onQuotaHit, onComplete);
    }
  }

  return (
    character && (
      <UnlockMessagesModal
        className="h-[calc(100vh-50px)] overflow-x-hidden overflow-y-scroll sm:h-auto sm:overflow-auto"
        idOverride={Modals.UnlockMessageReset}
        config={config}
        onComplete={onUnlockMessageComplete}
        character={character}
        closeOnSuccess={true}
      />
    )
  );
};
