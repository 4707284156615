import React from 'react';
import { ICharacter } from '@/models/characters';
import { Text } from '@/components/Text';
import { default_avatar } from '@/assets/remote-assets';
import { cn } from '@/lib/utils';
import { SkeletonImage } from '@/components/SkeletonImage';
import { getPersonalityAndRelationship } from '@/components/character-creation/util';
import { useRouter } from 'next/router';

interface Props {
  character: ICharacter;
  loading?: boolean;
  className?: string;
  centered?: boolean;
}

export const ChatWelcomeMessage: React.FC<Props> = ({ character, loading, className, centered }) => {
  const { push } = useRouter();

  const renderDescription = () => {
    if (character.created_by_user && character.description) {
      const { personality: personalityObj, relationship: relationshipObj } = getPersonalityAndRelationship(character.description);
      return (
        <div className="flex mt-1 items-left">
          {personalityObj && (
            <div className="bg-base-100 rounded-sm px-2 py-1 mr-2 flex items-center z-10 w-max">
              <span className="mr-2 flex-shrink-0">{personalityObj.icon}</span>
              <Text variant="body-2" className="text-white whitespace-nowrap">{personalityObj.name}</Text>
            </div>
          )}
          {relationshipObj && (
            <div className="bg-base-100 rounded-sm px-2 py-1 flex items-center z-10 w-max">
              <span className="mr-2 flex-shrink-0">{relationshipObj.icon}</span>
              <Text variant="body-2" className="text-white whitespace-nowrap">{relationshipObj.name}</Text>
            </div>
          )}
        </div>  
      );
    }
    return character.description ? character.description.replace(/\{.*?\}/g, '').trim() : character.description;
  }

  return (
    <div className={cn('flex items-center mb-8', centered && 'flex-col text-center gap-1', className)}>
      <button
        onClick={(event) => {
          event.stopPropagation();
          push(`/character/${character.id}`);
        }}
        className="focus:outline-none hover:brightness-110 transition duration-300 brightness-75 cursor-pointer"
      >
        <SkeletonImage
          className={cn(
            'rounded-full object-cover object-top border-white/100 hover:border-2',
            centered ? 'h-16 w-16' : 'h-12 w-12 mr-2'
          )}
          height={centered ? 64 : 48}
          width={centered ? 64 : 48}
          src={character.avatar_url || default_avatar}
          alt="avatar"
        />
      </button>
      <div>
        <Text className={cn('text-white -mb-0.5')} variant={centered ? 'h3' : 'h5'}>
          {character.name}
        </Text>
        <Text
          className={cn('text-white max-w-[500px]', !centered && 'line-clamp-1 max-w-[300px]')}
          variant={centered ? 'body-1' : 'body-2'}
        >
          {/* Remove all markdown formatting, when the character is created by the user, the description will contain a json to string to record personality and relationship */}
          {loading ? 'Updating...' 
                : renderDescription()}
        </Text>
      </div>
    </div>
  );
};
