import { FC, memo } from 'react';
import Image from 'next/image';
import { Text } from '@/components/Text';
import { cn } from '@/lib/utils';
import { OnlineBadge } from './OnlineBadge';
import ResetIcon from '@/assets/icons/reset-icon.svg';
import BinIcon from '@/assets/icons/bin-icon.svg';
import { IRecentChat } from '@/models/chat';
import { default_avatar } from '@/assets/remote-assets';

type Props = {
  item: IRecentChat;
  className?: string;
  onClick: () => void;
  onDelete: (id: number) => void;
  onReset: (id: number) => void;
  isActive: boolean;
};

const SidebarChatItem: FC<Props> = ({ className, onClick, item, isActive, onDelete, onReset }) => {
  const handleDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    onDelete(item.character_id);
  };

  const handleReset = (event: React.MouseEvent) => {
    event.stopPropagation();
    onReset(item.character_id);
  };

  const activeClass = 'bg-brown border-pink';
  const actionsActiveClass = 'block';

  return (
    <div
      onClick={onClick}
      className={cn(
        'flex items-center justify-between border border-transparent rounded-lg cursor-pointer group py-2 pl-4 bg-transparent hover:bg-brown transition-all duration-300',
        isActive ? activeClass : '',
        className,
      )}
    >
      <div className="flex">
        <div className="block relative mr-2">
          <div className="block w-12 h-12 rounded-full overflow-hidden">
            <Image
              width={48}
              height={48}
              className="w-full h-auto object-cover"
              src={item.avatar_url || default_avatar}
              alt=""
            />
          </div>
          {/* // TODO: Need a param for the online badge */}
          {!!item.last_interaction_time && <OnlineBadge className="absolute bottom-0 right-1" />}
        </div>
        <div className="flex flex-col justify-center">
          <Text className="text-white truncate w-36" variant={'h5'}>
            {item.character_name}
          </Text>
          <Text className="text-white/[.8] truncate w-48" variant={'body-1'}>
            {item.msg_preview}
          </Text>
        </div>
      </div>
      <div className="flex justify-self-end">
        <button
          onClick={handleReset}
          className={cn('mr-5 lg:mr-2 block lg:hidden lg:group-hover:block', isActive ? actionsActiveClass : '')}
          aria-label="reset"
        >
          <ResetIcon />
        </button>
        {/* <button
          onClick={handleDelete}
          className={cn('block lg:hidden lg:group-hover:block', isActive ? actionsActiveClass : '')}
          aria-label="delete"
        >
          <BinIcon />
        </button> */}
      </div>
    </div>
  );
};

export default memo(SidebarChatItem);
