import { cn } from '@/lib/utils';
import { useCharactersStore } from '@/stores/characters';
import { Modals } from '@/types/modals';
import { IChatQuotaConfig } from '@/types/chat';
import { showToast } from '@/stores/app';
import { ToastType } from '@/types/toast';
import { ICharacter } from '@/models/characters';
import { IQuotaConfig } from '@/types/quota';
import { UnlockBaseModal } from '@/components/modals/UnlockBaseModal';
import posthog from 'posthog-js';

type Props = {
  idOverride?: string;
  className?: string;
  onComplete: () => Promise<void>;
  closeOnSuccess?: boolean;
  config?: IChatQuotaConfig;
  character: ICharacter;
};

export const UnlockMessagesModal: React.FC<Props> = ({ idOverride, className, config, onComplete, character, closeOnSuccess = false }) => {
  const { unlockMessages } = useCharactersStore(s => ({ unlockMessages: s.unlockMessages }));
  const quotaConfig : IQuotaConfig = {
    text: 'Wanna keep chatting?',
    subtitle: 'Send me some 💎 so we can continue the fun',
    success_text: 'Message me now',
    message_locked: config?.message_locked || false,
    currency: "gem",
    cost: config?.gem_cost || 0,
  };

  async function onUnlockMessages() : Promise<boolean> {
    if (!config) {
      showToast('Unable to unlock, cost unknown', ToastType.ERROR);
      return false;
    }

    const success = await unlockMessages(config, character.id);
    if (success) {
      window.gtag && window.gtag('event', 'unlock_messages_succeess');
      posthog.capture('unlock_messages_success');
    } 
    return success;
  }

  return (
    <UnlockBaseModal
      className={cn("h-[calc(100vh-50px)] overflow-x-hidden overflow-y-scroll sm:h-auto sm:overflow-auto", className)} 
      idOverride={idOverride || Modals.UnlockMessage}
      config={quotaConfig}
      character={character}
      onUnlockAction={onUnlockMessages}
      onComplete={onComplete}
      closeOnSuccess={closeOnSuccess}
    />     
  );
};
