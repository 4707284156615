import { cn } from '@/lib/utils';
import { Modals } from '@/types/modals';
import { Text } from '@/components/Text';
import { PatternModal } from '@/components/PatternModal';
import { Button } from '@/components/buttons/Button';
import { closeModal, openModal } from '@/components/BaseModal';
import { useCharactersStore } from '@/stores/characters';
import { IChatQuotaConfig } from '@/types/chat';
import { Player } from '@lottiefiles/react-lottie-player';
import dotsAnimation from '@/assets/animations/dots.json';

type Props = {
  characterId?: number;
  onQuotaHit: (config: IChatQuotaConfig) => void;
  onComplete: () => void;
};

export const ResetChatModal: React.FC<Props> = ({ characterId, onQuotaHit, onComplete }) => {
  const { getById, resetChat, resetingChat } = useCharactersStore(s => ({
    getById: s.getById,
    resetChat: s.resetChat,
    resetingChat: s.resetingChat,
  }));

  const character = characterId ? getById(characterId) : undefined;

  function onReset() {
    if (characterId) {
      resetChat(characterId, onQuotaHit, onComplete);
    }
  }

  return (
    <PatternModal id={Modals.ResetChat}>
      <div className="flex flex-col items-center">
        <div className="flex w-full max-w-[280px]">
          <Text variant="h5" className="text-white text-center">
            {character
              ? `Are you sure you want to reset the chat with ${character?.name}? all msgs will be deleted.`
              : 'Are you sure you want to reset this chat? all msgs will be deleted.'}
          </Text>
        </div>
        <div className="py-[10px] w-full items-center mb-8">
          {resetingChat? ( 
            <Player autoplay loop src={dotsAnimation} style={{ height: '40px', width: '80px' }} />
          ):(
            "\u00A0"
          )
          }
        </div>
        <div className="flex justify-center sm:justify-between w-full">
          <Button
            onClick={onReset}
            className="w-auto mr-4 sm:mr-0"
            disabled={resetingChat}
            wrapperClass={cn(`bg-pink group-active/button:bg-black`, resetingChat && 'bg-gray')}
          >
            <Text className="px-[60px]" textColor="white" variant="btn-1">
              Ok
            </Text>
          </Button>
          <Button
            onClick={() => closeModal(Modals.ResetChat)}
            disabled={resetingChat}
            className="w-auto"
            wrapperClass="bg-white group-active/button:bg-black"
          >
            <Text className="px-11 text-black group-active/button:text-white" variant="btn-1">
              Cancel
            </Text>
          </Button>
        </div>
      </div>
    </PatternModal>
  );
};
