import { cn } from '@/lib/utils';
import { useRouter } from 'next/router';
import { FC, PropsWithChildren } from 'react';

export const Main: FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useRouter();
  const isChat = pathname === '/chat/[id]';

  return (
    <main
      className={cn(
        'px-5 md:px-8 md:pb-2 pb-[14px] flex-1 z-[16] h-[calc(100dvh-75px)] custom-scrollbar',
        isChat ? 'overflow-y-hidden' : 'overflow-x-hidden',
      )}
    >
      {children}
    </main>
  );
};
