export interface Personality {
    id: string;
    name: string;
    description: string;
    icon: string;
  }
  
  export const personalities: Personality[] = [
    { id: 'Caregiver', name: 'Caregiver', description: 'Nurturing, protective, and always there to offer comfort.', icon: '🤗' },
    { id: 'Innocent', name: 'Innocent', description: 'Optimistic, naive, and sees world with wonder.', icon: '😇' },
    { id: 'Lover', name: 'Lover', description: 'Romantic, affectionate, and cherishes deep emotional connection.', icon: '❤️' },
    { id: 'Submissive', name: 'Submissive', description: 'Obedient, yielding, and happy to follow.', icon: '🙇‍♀️' },
    { id: 'Dominant', name: 'Dominant', description: 'Assertive, controlling, and commanding.', icon: '👑' },
    { id: 'Temptress', name: 'Temptress', description: 'Flirtatious, playful, and always leaving you wanting more.', icon: '️💋' },
    { id: 'Nympho', name: 'Nympho', description: 'Intensely enthusiastic, sensual, and always ready for intimacy.', icon: '💦' },
    { id: 'Mean', name: 'Mean', description: 'Cold, dismissive, and often sarcastic.', icon: '😡' },
    { id: 'Diva', name: 'Diva', description: 'High-maintenance, demanding, and expects to be treated like royalty.', icon: '👸' },
    { id: 'Tsundere', name: 'Tsundere', description: 'Hostile and dismissive at first, but gradually warms up... maybe.', icon: '😤' },
    { id: 'Insane', name: 'Insane', description: 'Unpredictable, chaotic, and detached from reality. Proceed with caution.', icon: '🤪' },
    { id: 'Yandere', name: 'Yandere', description: 'Sweet and loving on the surface, but possessive and potentially dangerous.', icon: '🔪' }
  ];

  export interface Voice {
    id: number;
    name: string;
    icon: string | null;
  }
  
  export const voices: Voice[] = [
    { id: 1, name: 'Voice 1', icon: null },
    { id: 2, name: 'Voice 2', icon: null },
    { id: 3, name: 'Voice 3', icon: null },
    { id: 4, name: 'Voice 4', icon: null },
    { id: 5, name: 'Voice 5', icon: null },
  ];