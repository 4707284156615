import { FC } from 'react';
import Image from 'next/image';
import { Text } from '@/components/Text';
import { cn } from '@/lib/utils';

type Props = {
  className?: string;
};

export const OnlineBadge: FC<Props> = ({ className }) => {
  return <div className={cn('flex bg-green border-2 border-black rounded-full w-2.5 h-2.5', className)}></div>;
};
