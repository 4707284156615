import { PropsWithChildren, useEffect } from 'react';
import { cn } from '@/lib/utils';
import { Sidebar } from '@/components/sidebar/Sidebar';
import { Header } from '@/components/Header';
import { useAppStore } from '@/stores/app';
import { useIsAuthenticated } from '@/hooks/useIsAuthenticated';
import { Main } from './Main';
import useIsMobile from '@/hooks/useIsMobile';

type Props = PropsWithChildren<{
  className?: string;
}>;

export const SidebarLayout = ({ children, className }: Props) => {
  const { isSidebarOpen, toggleSidebar } = useAppStore(s => s);

  const isLoggedIn = useIsAuthenticated();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (isLoggedIn && !isSidebarOpen && !isMobile) {
      toggleSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <div className={cn('flex min-h-dvh bg-black-200 h-dvh flex-1 overflow-hidden', className)}>
      <Sidebar toggleSidebar={toggleSidebar} isOpen={isSidebarOpen} />
      <div className="flex flex-col flex-1 h-dvh max-w-full overflow-hidden">
        <Header />
        <Main>{children}</Main>
      </div>
    </div>
  );
};
