import { PropsWithChildren } from 'react';
import { useCountdownTimer } from '@/hooks/useCountdownTimer';
import Image from 'next/image';
import { cn } from '@/lib/utils';
import dotsAnimation from '@/assets/animations/dots.json';
import { Modals } from '@/types/modals';
import { Text } from '@/components/Text';
import { UnlockButton } from '@/components/buttons/UnlockButton';
import { Player } from '@lottiefiles/react-lottie-player';
import { BaseModal, closeModal } from '../BaseModal';
import { IQuotaConfig } from '@/types/quota';
import { useState } from 'react';
import { ICharacter, SimplifiedCharacter } from '@/models/characters';
import { Button } from '@/components/buttons/Button';
import CloseIcon from '@/assets/icons/close-icon.svg';

type Props = PropsWithChildren<{
  idOverride?: string;
  className?: string;
  closeOnSuccess?: boolean;
  config?: IQuotaConfig;
  character: ICharacter | SimplifiedCharacter;
  onUnlockAction: () => Promise<boolean>;
  onComplete: () => Promise<void>;
}>;

export const UnlockBaseModal: React.FC<Props> = ({ children, idOverride, className, config, character, closeOnSuccess = false, onUnlockAction, onComplete }) => {
  const [state, setState] = useState<'default' | 'success'>('default');
  const [loading, setLoading] = useState(false);

  const { timeLeft, timeStr } = useCountdownTimer(config?.remaining_time ?? 0, onClose);

  async function onSuccess() {
    await onComplete();
    if (closeOnSuccess) {
      onClose();
    }
    else {
      setState('success');
    }
  }

  function onError() {
  }

  async function onUnlock() {
    setLoading(true);
    const success = await onUnlockAction();
    if (success) {
      await onSuccess();
    }
    else {
      onError();
    }
    setLoading(false);
  }

  function onClose() {
    setState('default');
    closeModal(idOverride || Modals.UnlockMessage);
  }

  const renderSuccess = () => {
    return (
      <div className="absolute bottom-0 inset-x-0 flex flex-col items-center justify-center p-5 text-left bg-gradient-to-b from-black/0 via-black/75 to-black/100">
        <Text className="text-white w-9/12 items-left mt-40 mb-5.5 text-2xl" variant="h3">
        Gems Accepted. Thank You!
        </Text>
        <Text className="text-white w-9/12 items-left mb-5.5 text-sm" variant="body-2">
        </Text>
        <Button
            onClick={onClose}
            className="w-9/12 mt-2"
            wrapperClass="py-[10px] bg-blue-gr group-active/button:bg-white-gr"
          >
            <Text
              className="transition-all duration-150 text-white group-active/button:text-black"
              textColor="white"
              variant="btn-1"
            >
              {config?.success_text || 'Message me now'}
            </Text>
        </Button>
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <div className="absolute bottom-0 inset-x-0 flex flex-col items-center justify-center p-5 text-left bg-gradient-to-b from-black/0 via-black/75 to-black/100">
        <Text className="text-white w-8/12 items-left mt-40 mb-5.5 text-2xl" variant="h3">
        {config?.currency === 'dollar' ? 'Unlocking...' : 'Sending Gems...'}
        </Text>
        <Text className="text-white w-8/12 items-left mb-5.5 text-sm" variant="body-2">
        Calling {character.name}. Please wait.
        </Text>

        <div className="py-[10px] w-8/12 items-center">
          <Player autoplay loop src={dotsAnimation} style={{ height: '40px', width: '80px' }} />
        </div>
      </div>
    );
  };

  const renderDefault = () => {
    return (
      <div className="absolute bottom-0 inset-x-0 flex flex-col items-center justify-center p-5 text-left bg-gradient-to-b from-black/0 via-black/75 to-black/100">
        <Text className="text-white w-9/12 items-left mt-40 mb-5.5 text-2xl" variant="h3">
        {config?.text || 'Wanna keep chatting?'}
        </Text>
        <Text className="text-white w-9/12 items-left mb-5.5 text-sm" variant="body-2">
        {config?.subtitle || 'Send me some 💎 so we can continue the fun'}
        </Text>

        {config?.cost && (
          <UnlockButton
            className="w-9/12 mt-2 items-center"
            wrapperClassName="py-[12px]"    
            onClick={onUnlock}
            currency={config?.currency}
            title={config?.button_text || 'Send'}
            amount={config?.cost}
          />
        )}
      </div>
    );
  };


  return (
    <BaseModal
      className={cn("h-[calc(100vh-50px)] overflow-x-hidden overflow-y-scroll sm:h-auto sm:overflow-auto", className)} 
      id={idOverride || Modals.UnlockMessage}
    >
      <div className="flex flex-col items-center justify-center p-5 w-full h-full">
        <div className="flex flex-col items-center w-full max-w-[540px] relative bg-black rounded-3xl border border-gray-700 overflow-hidden">
          <div className="relative w-full h-[600px]">
            {
              character.avatar_url && (
                <Image
                  src={character.avatar_url}
                  alt={character.name || ''}
                  layout="fill"
                  objectFit="cover"
                  className="w-full h-full object-top"
                />
              )
            }
            
            <button
              onClick={onClose}
              className="absolute right-2 top-2 rounded-full bg-white/[0.25] p-1 ring-offset-background transition-opacity hover:opacity-100 hover:bg-white/[0.5] disabled:pointer-events-none">
              <CloseIcon />
            </button>

            {!loading && state === 'default' && renderDefault()}
            {!loading && state === 'success' && renderSuccess()}
            {loading && renderLoading()}

          </div>
            <div className="absolute bottom-0 items-center justify-center">
              <Text className={cn(`mt-2 text-white/50 whitespace-nowrap`)} variant="h6">
              {timeLeft ? timeStr : ''}
              </Text>
            </div>
        </div>
      </div>
      {children}
    </BaseModal>
  );
};
