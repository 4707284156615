import { Modals } from '@/types/modals';
import { Text } from '@/components/Text';
import { PatternModal } from '@/components/PatternModal';
import { Button } from '../buttons/Button';
import { closeModal } from '../BaseModal';
import { useCharactersStore } from '@/stores/characters';
import { useRouter } from 'next/router';

type Props = {
  characterId?: number;
};

export const DeleteChatModal: React.FC<Props> = ({ characterId }) => {
  const { getById, deleteChat, deletingChat } = useCharactersStore(s => ({
    getById: s.getById,
    deleteChat: s.deleteChat,
    deletingChat: s.deletingChat,
  }));
  const router = useRouter();

  const character = characterId ? getById(characterId) : undefined;
  const activeChatId = router.asPath.startsWith('/chat/') ? Number(router.query.id) : null;

  function onDeleted() {
    closeModal(Modals.DeleteChat);
    const isChatOpened = activeChatId === characterId;

    if (isChatOpened) {
      router.push('/');
    }
  }

  function onDelete() {
    if (characterId) {
      deleteChat(characterId, onDeleted);
    }
  }

  return (
    <PatternModal id={Modals.DeleteChat}>
      <div className="flex flex-col items-center">
        <div className="flex w-full max-w-[280px]">
          <Text variant="h5" className="mb-8 text-white text-center">
            {character
              ? `Are you sure you want to delete the chat with ${character?.name}? all msgs will be deleted.`
              : 'Are you sure you want to delete the chat with? all msgs will be deleted.'}
          </Text>
        </div>
        <div className="flex justify-center sm:justify-between w-full">
          <Button
            onClick={onDelete}
            className="w-auto mr-4 sm:mr-0"
            disabled={deletingChat}
            wrapperClass="bg-white group-active/button:bg-black"
          >
            <Text className="px-[60px] text-black group-active/button:text-white" variant="btn-1">
              Ok
            </Text>
          </Button>
          <Button
            onClick={() => closeModal(Modals.DeleteChat)}
            disabled={deletingChat}
            className="w-auto"
            wrapperClass="bg-pink group-active/button:bg-black"
          >
            <Text className="px-11" textColor="white" variant="btn-1">
              Cancel
            </Text>
          </Button>
        </div>
      </div>
    </PatternModal>
  );
};
